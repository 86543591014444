:root {
 --mount-meadow: #ed268f;
 --white-smoke: #fcef03;
 --night-rider: #030303;
 --black: #191b17;
 --bg-color: #f0f0f0;
 --transition: all 0.5s ease-in-out;
}
* {
 font-family: "Hind Siliguri", sans-serif;
 padding: 0;
 margin: 0;
 box-sizing: border-box;
}

html {
 color: var(--black);
 font-size: 1rem;
 font-weight: 300;
 line-height: 1.5;
 text-rendering: optimizeLegibility;
 scroll-behavior: smooth;
}

#logo {
 max-width: 100px;
 max-height: 100px;
 padding: 0px 0px 0px 0px;
}

/* setup & reusable stylings */
img {
 width: 100%;
}

.feature-left img {
 width: 80%;
 margin: 0 auto;
}

a {
 text-decoration: none;
 color: var(--black);
}
li {
 list-style-type: none;
}
button {
 cursor: pointer;
 outline: 0;
 background: transparent;
}
.container {
 max-width: 1320px;
 margin: 0 auto;
 padding: 0 1rem;
}
h1,
h2,
h3,
h5,
h6 {
 margin: 0.6rem 0;
 line-height: 1.25;
 font-weight: 400;
 text-transform: capitalize;
}
h4 {
 font-size: 2.66rem;
 margin: 0.6rem 0;
 line-height: 1.25;
 font-weight: 400;
 text-transform: capitalize;
}
.text {
 margin: 0.6rem 0;
 opacity: 0.8;
}
.center {
 display: flex;
 justify-content: center;
 align-items: center;
}
section {
 padding: 5rem 0;
}
.title {
 padding: 0.6rem 0;
 text-align: center;
}
.title h2 {
 font-size: 2.2rem;
 color: var(--night-rider);
}
.title .text {
 font-weight: 500;
 font-size: 1.1rem;
}

/*DETAIL*/

.detail2-item {
 color: #ffffff;
}
.detail-item {
 color: #fff;
}
.detail3-item {
 color: #fff;
}
.detail {
 background: #ffffff;
}
.detail-item {
 perspective: 1000;
 -webkit-perspective: 1000;
 backface-visibility: hidden;
 -webkit-backface-visibility: hidden;
 background: var(--mount-meadow);
 text-align: center;
 box-shadow: 0 1px 2px 0 rgba(90, 91, 95, 0.1);
 -webkit-box-shadow: 0 1px 2px 0 rgba(90, 91, 95, 0.1);
 -moz-box-shadow: 0 1px 2px 0 rgba(90, 91, 95, 0.1);
 margin: 2rem 0;
 padding: 3.5rem 1.5rem;
 border-radius: 4px;
 transition: var(--transition);
 transform: translateY(-1px);
}

.detail-item:hover {
 -webkit-box-shadow: 0 21px 19px -2px rgba(217, 217, 217, 1);
 -moz-box-shadow: 0 21px 19px -2px rgba(217, 217, 217, 1);
 box-shadow: 0 21px 19px -2px rgba(217, 217, 217, 1);
 transform: translateY(-7px);
}
.detail-item span {
 font-size: 2.5rem;
}
.detail-item h2 {
 font-size: 1.4rem;
 opacity: 0.8;
 font-weight: 300;
}
.row .detail-item {
 background-image: linear-gradient(
   rgba(16, 166, 217, 0.8),
   rgba(16, 166, 217, 0.7)
  ),
  url(asset/bg-new.jpg);
 background-size: cover;
 background-repeat: no-repeat;
 background-position: center;
 background-attachment: fixed;
}
.detail2 {
 background: #ffffff;
}
.detail2-item {
 perspective: 1000;
 -webkit-perspective: 1000;
 backface-visibility: hidden;
 -webkit-backface-visibility: hidden;
 background: var(--mount-meadow);
 text-align: center;
 box-shadow: 0 1px 2px 0 rgba(90, 91, 95, 0.1);
 -webkit-box-shadow: 0 1px 2px 0 rgba(90, 91, 95, 0.1);
 -moz-box-shadow: 0 1px 2px 0 rgba(90, 91, 95, 0.1);
 margin: 2rem 0;
 padding: 3.5rem 1.5rem;
 border-radius: 4px;
 transition: var(--transition);
 transform: translateY(-1px);
}

.detail2-item:hover {
 -webkit-box-shadow: 0 21px 19px -2px rgba(217, 217, 217, 1);
 -moz-box-shadow: 0 21px 19px -2px rgba(217, 217, 217, 1);
 box-shadow: 0 21px 19px -2px rgba(217, 217, 217, 1);
 transform: translateY(-7px);
}
.detail2-item span {
 font-size: 2.5rem;
}
.detail2-item h2 {
 font-size: 1.4rem;
 opacity: 0.8;
 font-weight: 300;
}
.row .detail2-item {
 background-image: linear-gradient(
   rgba(237, 38, 143, 0.8),
   rgba(237, 38, 143, 0.7)
  ),
  url(asset/bg-new.jpg);
 background-size: cover;
 background-repeat: no-repeat;
 background-position: center;
 background-attachment: fixed;
}
.detail3 {
 background: #ffffff;
}
.detail3-item {
 perspective: 1000;
 -webkit-perspective: 1000;
 backface-visibility: hidden;
 -webkit-backface-visibility: hidden;
 background: var(--mount-meadow);
 text-align: center;
 box-shadow: 0 1px 2px 0 rgba(90, 91, 95, 0.1);
 -webkit-box-shadow: 0 1px 2px 0 rgba(90, 91, 95, 0.1);
 -moz-box-shadow: 0 1px 2px 0 rgba(90, 91, 95, 0.1);
 margin: 2rem 0;
 padding: 3.5rem 1.5rem;
 border-radius: 4px;
 transition: var(--transition);
 transform: translateY(-1px);
}

.detail3-item:hover {
 -webkit-box-shadow: 0 21px 19px -2px rgba(217, 217, 217, 1);
 -moz-box-shadow: 0 21px 19px -2px rgba(217, 217, 217, 1);
 box-shadow: 0 21px 19px -2px rgba(217, 217, 217, 1);
 transform: translateY(-7px);
}
.detail3-item span {
 font-size: 2.5rem;
}
.detail3-item h2 {
 font-size: 1.4rem;
 opacity: 0.8;
 font-weight: 300;
}
.row .detail3-item {
 background-image: linear-gradient(rgba(3, 3, 3, 0.9), rgba(3, 3, 3, 0.8)),
  url(asset/bg-new.jpg);
 background-size: cover;
 background-repeat: no-repeat;
 background-position: center;
 background-attachment: fixed;
}
/*description*/
.description {
 padding-right: auto;
 background-color: #fff;
}
.description .title {
 text-align: left;
}

.feature {
 background-image: linear-gradient(
   rgba(16, 166, 217, 0.8),
   rgba(16, 166, 217, 0.7)
  ),
  url(asset/bg.jpg);
 background-size: cover;
 background-repeat: no-repeat;
 background-position: center;
 background-attachment: fixed;
}

/* header */
.header {
 background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)),
  url(./asset/uv4.jpg);
 min-height: 100vh;
 background-position: center;
 background-size: cover;
 background-repeat: no-repeat;
 background-attachment: fixed;
 color: #fff;
 display: flex;
 flex-direction: column;
 justify-content: stretch;
}
.navbar {
 position: fixed;
 top: 0;
 left: 0;
 width: 100%;
 background-color: #fff;
 z-index: 999;
}
.brand-and-toggler {
 font-size: 1.65rem;
 padding: 0.8rem 0;
 display: flex;
 justify-content: space-between;
 align-items: center;
}
.navbar-brand {
 font-weight: 500;
 color: var(--night-rider);
}
.navbar-brand span {
 color: var(--mount-meadow);
}
.navbar-toggler {
 border: none;
 color: #10a6d9;
}
.navbar-nav {
 margin: 0.2rem 0 0.6rem 0;
}
.nav-item {
 padding: 0.6rem 0;
}
.nav-link {
 text-transform: uppercase;
 font-size: 0.95rem;
 letter-spacing: 1.5px;
 font-weight: 400;
 opacity: 0.65;
 transition: var(--transition);
}
.nav-link:hover {
 color: #10a6d9 !important;
}
.navbar-collapse {
 display: none;
}
.hero-div {
 flex: 1;
 flex-direction: column;
 text-align: center;
}
.hero-div h1 {
 font-size: 2.55rem;
 padding-bottom: 0.4rem;
}
.hero-div p {
 color: #fff;
 font-size: 1.05rem;
 letter-spacing: 3px;
}
.hero-btns {
 margin-top: 2.8rem;
}
.hero-btns button {
 border: 3.5px solid #fff;
 border-radius: 2rem;
 padding: 0.8rem 2rem;
 text-transform: uppercase;
}
.btn-trans {
 color: #fff;
 transition: var(--transition);
 display: none;
}
.btn-trans:hover {
 background: var(--mount-meadow);
 border-color: var(--mount-meadow);
}
.btn-white {
 background: #fff;
 color: var(--night-rider);
 transition: var(--transition);
}
.btn-white:hover {
 background: transparent;
 color: #fff;
}

/* line */

.line {
 width: 45px;
 margin: 1.2rem auto;
 background-color: var(--mount-meadow);
 height: 2.5px;
}
.lineDetail {
 width: 45px;
 margin: 1.2rem auto;
 background-color: white;
 height: 2.5px;
}
.line3 {
 width: 64px;
 margin: 1.2rem auto;
 background-image: linear-gradient(
  to left,
  #030303 25%,
  #fcef03 25% 50%,
  #ed268f 50% 75%,
  #10a6d9 75%
 );
 height: 8px;
}

.line2 {
 width: 64px;
 background-image: linear-gradient(
  to left,
  #030303 25%,
  #fcef03 25% 50%,
  #ed268f 50% 75%,
  #10a6d9 75%
 );
 height: 8px;
 float: left;
 margin-left: 1.5rem auto;
}

/* feature +  image control*/
.feature .blue {
 background: #10a6d9;
}

.feature .title {
 text-align: left;
}

.feature-right {
 margin-top: 4rem;
}
.feature-item {
 margin: 1.4rem 0;
 display: flex;
}
.feature-item div h3 {
 margin-top: 0;
 font-size: 1.4rem;
 font-weight: 300;
 opacity: 0.85;
}
.feature-item span {
 font-size: 1.1rem;
 color: #fff;
 background: var(--mount-meadow);
 align-self: flex-start;
 padding: 0.4rem 0.7rem;
 border-radius: 2px;
 margin-right: 1.4rem;
}

/* FAQ */
.faq {
 background: #f9f9f9;
}
.faq .row {
 margin-top: 2rem;
}
.faq-item {
 margin: 0.6rem 0;
}
.faq-head {
 background-image: linear-gradient(
  rgba(16, 166, 217, 0.5),
  rgba(16, 166, 217, 0.4)
 );
 display: flex;
 justify-content: space-between;
 align-items: center;
 cursor: pointer;
 padding: 0.2rem 1.8rem;
}
.faq-head h3 {
 font-weight: 300;
 opacity: 0.8;
}
.faq-content {
 background: #fff;
 overflow: hidden;
 height: 0;
 padding: 0 1.8rem;
 transition: var(--transition);
}
.show-faq-content {
 height: fit-content;
 padding: 0.6rem 1.8rem;
}
.faq p {
 font-size: large;
}

/* contact */
.contact .row {
 margin-top: 2rem;
}
.contact .row > div {
 margin: 2rem 0;
 padding: 1rem 0;
}
.contact .row > div h2 {
 font-size: 1.4rem;
 font-weight: 300;
 opacity: 0.9;
}
.contact-left form input,
.contact-left form textarea {
 width: 100%;
 font-size: 1.1rem;
 margin: 0.2rem 0;
 padding: 0.8rem 0.6rem;
 border: none;
 border-bottom: 1.5px solid #f0f0f0;
 outline: 0;
}
.contact-left form input::placeholder,
.contact-left form textarea::placeholder {
 font-size: 1.1rem;
 font-weight: 300;
 opacity: 0.8;
}
.contact-left form input:focus,
.contact-left form textarea:focus {
 border-color: var(--night-rider);
}
.submit-btn {
 margin: 1rem 0;
 border: none;
 font-size: 1.3rem;
 color: #fff;
 background: #10a6d9;
 opacity: 0.9;
 padding: 0.8rem 3.4rem;
 border-radius: 2rem;
 transition: var(--transition);
}
@media (hover: hover) and (pointer: fine) {
 .submit-btn:hover {
  background: var(--black);
 }
}

.contact-right div {
 margin: 2rem 0;
}

/* footer */
.footer {
 text-align: center;
 background: #1d262d;
 background-size: cover;
 background-repeat: no-repeat;
 background-position: center;
 background-attachment: fixed;
 color: #10a6d9;
 padding: 2rem 0;
 height: 50vh;
}
.footer .text {
 opacity: 0.8;
}
.footer-links {
 display: flex;
 justify-content: center;
 margin-top: 1.6rem;
}
.footer-links a {
 color: #10a6d9;
 opacity: 0.8;
 width: 50px;
 height: 50px;
 margin: 0 0.2rem;
 border-radius: 50%;
 transition: var(--transition);
}
.footer-links a:hover {
 background: #fcef03;
}
.hypertext:hover {
 color: #10a6d9;
}
/* testimonial */
.testimonial {
 background-image: linear-gradient(
   rgba(16, 166, 217, 0.8),
   rgba(16, 166, 217, 0.7)
  ),
  url(asset/bg.jpg);
 background-size: cover;
 background-repeat: no-repeat;
 background-position: center center;
 background-attachment: fixed;
}
.testimonial .title h2,
.testimonial .title {
 color: #fff;
}
.testimonial .row {
 margin-top: 2rem;
}
.testimonial-img {
 border: 4px solid #fff;
 width: 120px;
 height: 120px;
 border-radius: 50%;
 margin: 2.5rem auto;
 padding: 0.5rem;
}
.testimonial-img img {
 border-radius: 50%;
}
.testimonial-item {
 text-align: center;
}
.testimonial-item p {
 font-size: 1.3rem;
 color: #fff;
 line-height: 1.7;
}
.testimonial-item span {
 margin-top: 0.7rem;
 display: block;
 font-size: 1.1rem;
 color: #fff;
 font-weight: 500;
}

/* testimonial2 */
.testimonial2 {
 background-image: linear-gradient(
   rgba(16, 166, 217, 0.8),
   rgba(16, 166, 217, 0.7)
  ),
  url(asset/bg1.jpg);
 background-size: cover;
 background-repeat: no-repeat;
 background-position: center center;
 background-attachment: fixed;
}
.testimonial2 .title h2,
.testimonial2 .title {
 color: #fff;
}
.testimonial2 .row {
 margin-top: 2rem;
}
.testimonial2-item {
 text-align: center;
}
.testimonial2-item p {
 font-size: 1.3rem;
 color: #fff;
 line-height: 1.7;
}
.testimonial2-item span {
 margin-top: 0.7rem;
 display: block;
 font-size: 1.1rem;
 color: #fff;
 font-weight: 500;
}

/* #### Media Queries #### */

@media screen and (min-device-width: 768px) {
 .detail .row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
 }
 .detail2 .row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
 }
 .detail3 .row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
 }
 .detail-item {
  margin: 0;
 }

 .detail2-item {
  margin: 0;
 }
 .detail3-item {
  margin: 0;
 }

 .faq .row {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
 }

 .contact .row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
 }
 .contact-right > div:first-child {
  margin-top: 0;
 }
 .testimonial-item p {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
 }
 .testimonial2-item p {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
 }
}

@media screen and (min-device-width: 992px) {
 .navbar {
  background: transparent;
  margin-top: 1.4rem;
 }
 .navbar .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
 }
 .navbar-brand {
  color: var(--white-smoke);
  font-size: 2rem;
 }
 .navbar-toggler {
  display: none;
 }
 .navbar-collapse {
  display: block !important;
 }
 .navbar-nav {
  display: flex;
  margin: 0;
 }
 .nav-item {
  margin-left: 1.8rem;
 }
 .nav-link {
  color: white;
 }
 .hero-div h1 {
  font-size: 3.8rem;
 }
 .btn-trans {
  display: inline-block;
  margin-right: 0.8rem;
 }

 /* navbar change */
 .cng-navbar {
  -webkit-box-shadow: 0 2px 5px 0 rgba(207, 205, 207, 0.3);
  -moz-box-shadow: 0 2px 5px 0 rgba(207, 205, 207, 0.3);
  box-shadow: 0 2px 5px 0 rgba(207, 205, 207, 0.3);
  margin-top: 0;
  background: #fff;
 }
 .cng-navbar .navbar-brand {
  color: var(--night-rider);
 }
 .cng-navbar .nav-link {
  color: var(--night-rider);
 }
 .feature .row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  column-gap: 0rem;
 }
 .feature-right {
  margin-top: 0;
 }
}

@media screen and (min-device-width: 1200px) {
 .hero-div h1 {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
 }
 .detail .row {
  grid-template-columns: repeat(3, 1fr);
 }
 .detail2 .row {
  grid-template-columns: repeat(3, 1fr);
 }
 .detail3 .row {
  grid-template-columns: repeat(3, 1fr);
 }

 .testimonial-item p {
  width: 60%;
 }
 .testimonial2-item p {
  width: 60%;
 }
 .contact .row {
  column-gap: 4rem;
 }
 .contact-right {
  padding-left: 4rem !important;
 }
}

@media screen and (max-device-width: 480px) {
 .detail-item {
  transform: none !important;
 }
 .detail3-item {
  transform: none !important;
 }
 .detail2-item {
  transform: none !important;
 }
}
@media screen and (max-device-width: 900px) {
 .feature-left img {
  width: 100%;
 }
 .detail-item {
  transform: none !important;
 }
 .detail3-item {
  transform: none !important;
 }
 .detail2-item {
  transform: none !important;
 }
 .testimonial-item {
  transform: none !important;
 }
 .testimonial2-item {
  transform: none !important;
 }
}
@media only screen and (max-device-width: 1366px) {
 .header {
  background-attachment: scroll;
 }
 .row .detail-item {
  background-attachment: scroll;
 }
 .row .detail2-item {
  background-attachment: scroll;
 }
 .row .detail3-item {
  background-attachment: scroll;
 }
 .testimonial2 {
  background-attachment: scroll;
 }
 .testimonial {
  background-attachment: scroll;
 }
}

.contact h2 {
 color: #2f4f4f;
}
